<template>
  <div class='p-8 bg-gray-100 text-center max-w-4xl'>
    재무팀 또는 해당 사건 관련인만 접근 가능합니다.
  </div>
</template>

<script>

export default {
  name: 'NoBillingAccess',
}
</script>
